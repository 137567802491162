@charset "UTF-8";

@use 'sass:math';
@use 'core';

/****************************************

****************************************/
.page-contents{
    max-width: var(--max-width);
}
/****************************************

****************************************/
.visual{
    clip-path: url(#visual-clip);
    max-width: var(--max-width);
    margin: 0 auto;
    text-align: center;
    a{
        display: block;
    }

    img{
        width: 100%;
    }
}

/****************************************

****************************************/
.greeting{
    text-align: center;
}

/****************************************

****************************************/
.entry-bnrs{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-between;
    a{
        display: block;
        width: 48%;
    }
}

/****************************************

****************************************/
.newss{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.news-section{
    margin-left: 2%;
    position: relative;
    width: 32%;

    @extend .animation-delays;

    &:first-child{
        margin-left: 0;
    }

    .btn-more{
        a{
            color: #ffffff;
            display: block;
            font-size: 14px;
            padding: 0 20px;
            text-decoration: none;
        }
        position: absolute;
        top: 10px;
        right: 0;
    }
}

.news-section__title{
    font-size: var(--font-size01);
    text-align: center;
}

.news-section__contents{
    background: #ffffff;
    margin-top: 20px;
    max-height: 300px;
    overflow: auto;
    padding: 20px;
}

#news-section--instagram{
    .news-section__contents{
        text-align: center;
    }
    .btn-more{
        display: inline-block;
        position: static;
    }
    .kipus-logo{
        margin-top: 20px;
    }
}
/****************************************

****************************************/
.notice-text{
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
    strong{
        display: block;
        font-size: 14px;
        margin-top: 20px;
    }
}
/****************************************

****************************************/
.feature{
    margin-top: 50px;
}
.feature__title{
    font-size: var(--font-size01);
    text-align: center;
}
.feature__contents{
    margin-top: 20px;
}
.feature-types{
    display: flex;
    flex-wrap: wrap;
    li{
        margin-left: percentage(math.div(40, 1400));
        width: percentage(math.div(320, 1400));
        &:first-child{
            margin-left: 0;
        }
        @extend .animation-delays;
    }
    a{
        display: block;
        height: 100%;
        opacity: 1;
        text-decoration: none;
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    img{
        width: 100%;
    }
}
.feature-type__image{
    margin: 0 auto;
    overflow: hidden;
    width: percentage($number: math.div(2, 3));
    img{
        -webkit-transition: transform .3s linear 0s;
        -moz-transition: transform .3s linear 0s;
        -o-transition: transform .3s linear 0s;
        -ms-transition: transform .3s linear 0s;
        transition: transform .3s linear 0s;
    }
}
.feature-type__title{
    font-size: var(--font-size02);
    margin-top: 10px;
    text-align: center;
}

.feature-type__link{
    font-size: var(--font-size03);
    margin-top: 10px;
    text-align: center;
}

/****************************************

****************************************/
.course{
    margin-top: 50px;
}
.course__title{
    font-size: var(--font-size01);
    text-align: center;
}
.course__contents{
    margin-top: 20px;
}

.course-list{
    display: flex;
    flex-wrap: wrap;
    li{
        margin-left: percentage(math.div(25, 1400));
        margin-top: 50px;
        width: percentage(math.div(450, 1400));
        &:nth-child(3n+1){
            margin-left: 0;
        }
        &:nth-child(-n+3){
            margin-top: 0;
        }
        @extend .animation-delays;
    }
    a{
        opacity: 1;
        text-decoration: none;
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
}
.course-list__image{
    margin: 0 auto;
    overflow: hidden;
    width: percentage($number: math.div(2, 3));
    img{
        width: 100%;
        -webkit-transition: transform .3s linear 0s;
        -moz-transition: transform .3s linear 0s;
        -o-transition: transform .3s linear 0s;
        -ms-transition: transform .3s linear 0s;
        transition: transform .3s linear 0s;
    }
}

.course-list__title{
    font-size: var(--font-size02);
    margin-top: 10px;
    text-align: center;
}

.course-list__link{
    font-size: var(--font-size03);
    margin-top: 10px;
    text-align: center;
}

/****************************************

****************************************/
.access{
    margin-top: 50px;
}
.access__title{
    font-size: var(--font-size01);
    text-align: center;
}
.access__contents{
    margin-top: 20px;
    .access__maps{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .access__map{
        clip-path: url(#map-clip);
        margin-top: 50px;
        padding-top: 40%;
        position: relative;
        width: 100%;
        &:first-child{
            margin-top: 0;
        }
    }
    iframe{
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

/****************************************
メディアクエリ
****************************************/
@media screen and (max-width: 1500px) {

}

@media screen and (max-width: 1200px) {
    .page-contents{
        padding-left: 20px;
        padding-right: 20px;
    }
    .newss{
        justify-content: space-between;
    }
    .news-section{
        margin: 50px 0 0 !important;
        width: 48%;
        &:nth-child(-n+2){
            margin-top: 0 !important;
        }
    }
    .feature-types{
        justify-content: space-between;
        li{
            margin: 50px 0 0 !important;
            width: 48%;
            &:nth-child(-n+2){
                margin-top: 0 !important;
            }
        }
    }
    .course-list{
        justify-content: space-between;
        li{
            margin: 50px 0 0 !important;
            width: 48%;
            &:nth-child(-n+2){
                margin-top: 0 !important;
            }
        }
    }

}

@media screen and (max-width: 900px) {
	.entry-bnrs{
		a{
            margin-top: 20px;
			width: 100%;
            &:first-child{
                margin-top: 0;
            }
            img{
                width: 100%;
            }
		}
	}
    .news-section{
        margin-top: 30px !important;
        width: 100%;
        &:nth-child(-n+2){
            margin-top: 30px !important;
        }
        &:first-child{
            margin-top: 0 !important;
        }
        .btn-more{
            margin-top: 20px;
            position: static;
        }
    }
    .feature-types{
        li{
            margin-top: 30px !important;
            width: 100%;
            &:nth-child(-n+2){
                margin-top: 30px !important;
            }
            &:first-child{
                margin-top: 0 !important;
            }
        }
    }
    .course-list{
        li{
            margin-top: 30px !important;
            width: 100%;
            &:nth-child(-n+2){
                margin-top: 30px !important;
            }
            &:first-child{
                margin-top: 0 !important;
            }

        }
    }
}

@media screen and (min-width: 765px) {

}

@media screen and (max-width: 764px) {

}

@media screen and (max-width: 700px) {

}

@media screen and (max-width: 600px) {
    .news-section__contents{
        padding: 10px;
    }
    .news-list{
        a{
            padding: 10px;
        }
    }
}
